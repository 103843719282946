// @flow
import styled, { css } from "styled-components";
import { useHistory } from "react-router";
import { Field, Form } from "react-final-form";
import { media } from "@nested/brand";
import {
  ADDRESS_NOT_FOUND_ID,
  StackedAddressPicker,
  onboardingCtaHandler,
  onlineValuationCtaHandler,
} from "./AddressPicker";
import { EmailCaptureForm as OriginalEmailCaptureForm } from "./EmailCaptureForm";

const EmailCaptureForm = styled(OriginalEmailCaptureForm)`
  margin: 30px auto 0;

  ${media.desktop`
    margin: 30px 0 0 0;
  `}
`;

const formStyles = css`
  margin: 30px auto 0;
  position: relative;
  width: 100%;
  max-width: 350px;
  ${media.tablet`
    max-width: 348px;
  `}
  ${media.desktop`
    margin: 30px 0 0;
    width: 80%;
    max-width: 354px;
  `}
`;

const required = (value) => (value ? undefined : "Required");

type Props = {
  formType?: "onboarding" | "emailSubscription" | "onlineValuation",
  stateOverride?: any,
  submitButtonText: string,
};

export const HeroForm = ({
  formType,
  stateOverride,
  submitButtonText,
}: Props) => {
  if (formType === "emailSubscription") {
    return <EmailCaptureForm submitButtonText={submitButtonText} />;
  }
  const history = useHistory();
  const onSubmit =
    formType === "onlineValuation"
      ? onlineValuationCtaHandler("hero-address-cta", history)
      : onboardingCtaHandler("hero-address-cta", history);
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        "hero-address-cta":
          (stateOverride && stateOverride["hero-address-cta"]) || null,
      }}
    >
      {({ handleSubmit, pristine, submitting, hasValidationErrors }) => (
        <form
          onSubmit={handleSubmit}
          css={formStyles}
          data-test="hero-address-cta-form"
        >
          <Field validate={required} name="hero-address-cta">
            {({ input, meta }) => (
              <StackedAddressPicker
                dataTest="hero-address-cta"
                placeholder="Enter your postcode"
                disabled={pristine || hasValidationErrors || submitting}
                includeBlacklisted
                includeNotInServicedArea
                allowAddressNotFound
                withPin
                stateOverride={
                  stateOverride &&
                  stateOverride["hero-address-cta-stateOverride"]
                }
                submitButtonText={submitButtonText}
                {...input}
                {...meta}
                onChange={(address) => {
                  input.onChange(address);
                  if (address.id === ADDRESS_NOT_FOUND_ID) {
                    handleSubmit();
                  }
                }}
              />
            )}
          </Field>
        </form>
      )}
    </Form>
  );
};
