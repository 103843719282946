// @flow

// Shamelessly copied from https://emailregex.com/
const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line

export const isValidEmail = (email: string): boolean => EMAIL_REGEX.test(email);

export const getValidEmail = (email: string): ?string => {
  const trimmed = email.trim();
  if (isValidEmail(trimmed)) {
    return trimmed;
  }
  return null;
};
