// @flow
import { getConfig } from "@nested/config";
import { Sentry } from "@nested/isomorphic-sentry";

const { GRAPHQL_URI } = getConfig();

// Using a tagged template called 'gql' means apollo will add it to our CI checks
const gql = (strings) => strings.join("");

const MUTATION = gql`
  mutation TriggerZapierWebhook($input: TriggerZapInput!) {
    triggerZap(input: $input) {
      successful
    }
  }
`;

export const triggerZapierWebhook = async (
  webhookId: string,
  payload: any,
): Promise<true | void> => {
  try {
    const response = await fetch(GRAPHQL_URI, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: MUTATION,
        variables: {
          input: {
            webhookId,
            // must be encoded twice as the graphql type is string
            payload: JSON.stringify(payload),
          },
        },
      }),
    });
    const json = await response.json();
    if (json?.data?.triggerZap?.successful !== true) {
      throw new Error(`Error response from Zapier: ${JSON.stringify(json)}`);
    }
    return true;
  } catch (e) {
    Sentry.captureException(e);
    throw e;
  }
};
