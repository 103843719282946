//  @flow
import { getConfig } from "@nested/config";

import { isValidEmail } from "../../emailValidation/emailValidation";
import { triggerZapierWebhook } from "./triggerZapierWebhook";

const { COVID_SIGN_UP_ZAP_ID } = getConfig();

export const subscribeToNewsletterZap = async (email: string) => {
  if (!isValidEmail(email)) {
    return "Please provide a valid email address.";
  }

  try {
    await triggerZapierWebhook(COVID_SIGN_UP_ZAP_ID, { email });
    return undefined;
  } catch (e) {
    return "Sorry, something went wrong. Please try again later.";
  }
};
