import { css } from "styled-components";
import { Button } from "@nested/component-library";
import { AddressPicker } from "./AddressPicker";

const inputIllusionBox = css`
  background-color: white;
  border: 1px solid ${({ theme }) => theme.palette.hague40};
  border-top: none;
  border-radius: 0 0 4px 4px;
  padding: 0 10px 10px;
  button {
    width: 100%;
    height: 50px;
    color: ${({ theme }) => theme.palette.hague130};
  }
  button:hover,
  button:disabled:hover {
    background-color: ${({ theme }) => theme.palette.terracotta90};
    color: ${({ theme }) => theme.palette.hague130};
  }
  button:active,
  button:disabled:active {
    background-color: #ff6d56;
    color: ${({ theme }) => theme.palette.hague130};
  }
  button:disabled {
    background-color: ${({ theme }) => theme.palette.terracotta100};
    color: ${({ theme }) => theme.palette.hague130};
  }
`;

export const StackedAddressPicker = ({
  disabled,
  submitButtonText,
  ...rest
}) => (
  <>
    <AddressPicker {...rest} hasButtonBelow />
    <div css={inputIllusionBox} className="illusion-box">
      <Button
        disabled={disabled}
        type="accent"
        data-test="stacked-address-picker-submit"
      >
        {submitButtonText}
      </Button>
    </div>
  </>
);
